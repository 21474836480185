<template>
	<div>
		<b-modal
			id="modal-edit-bonus"
			cancel-variant="outline-secondary"
			ok-title="Update"
			cancel-title="Close"
			ok-variant="primary"
			centered
			title="Update Bonus Code"
			size="lg"
			no-enforce-focus
			@ok="confirmEditBonus"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationFormBonus)"
					@reset.prevent="resetForm"
				>
					<b-row>
						<!-- Name -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="name"
							>
								<label class="mb-1"><span class="text-danger">*</span> Name</label>
								<validation-provider
									#default="{ errors }"
									name="bonusName"
									rules="required"
								>
									<b-form-input
										id="name"
										v-model="bonusData.name"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										placeholder="Enter Bonus Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Group -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="group"
							>
								<label class="mb-1"><span class="text-danger">*</span> Group</label>
								<validation-provider
									#default="{ errors }"
									name="group"
									rules="required"
								>
									<v-select
										v-model="bonusData.bonus_group_id"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="groupNameOptions"
										:reduce="val => val.id"
										:clearable="false"
										placeholder="Select Bonus Group"
										label="name"
										input-id="group"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Name -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="code"
							>
								<label class="mb-1">Code</label>
								<validation-provider
									name="code"
								>
									<b-form-input
										id="code"
										v-model="bonusData.code"
										placeholder="Enter bonus code"
									/>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Value -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="value"
							>
								<label class="mb-1">Value</label>
								<validation-provider
									#default="{ errors }"
									name="value"
									rules="numeric|min_value:1"
								>
									<b-form-input
										id="value"
										v-model="bonusData.value"
										placeholder="Enter bonus value, Must be at least 1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Type -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="type"
							>
								<label class="mb-1"><span class="text-danger">*</span> Type</label>
								<validation-provider
									#default="{ errors }"
									name="type"
									rules="required"
								>
									<v-select
										v-model="bonusData.type"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="typeOptions"
										:reduce="val => val.value"
										:clearable="false"
										placeholder="Select Type"
										input-id="type"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Rollover -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="rollover"
							>
								<label class="mb-1">Rollover</label>
								<validation-provider
									#default="{ errors }"
									name="rollover"
									rules="numeric|numeric|min_value:1|max_value:50"
								>
									<b-form-input
										id="rollover"
										v-model="bonusData.rollover"
										placeholder="Enter bonus rollover, Must be from 1 - 50."
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Currency -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="currency"
							>
								<label class="mb-1">Currency</label>
								<validation-provider
									#default="{ errors }"
									name="currency"
									rules=""
								>
									<v-select
										v-model="bonusData.currency"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="currencyOptions"
										:reduce="val => val.value"
										:clearable="true"
										placeholder="Select Currency"
										label="value"
										input-id="currency"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Max Bonus Amount -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="maxAmount"
							>
								<label class="mb-1">Max Bonus Amount</label>
								<validation-provider
									#default="{ errors }"
									name="maxAmount"
									rules="numeric"
								>
									<b-form-input
										id="maxAmount"
										v-model="bonusData.max_bonus_amount"
										placeholder="Enter max bonus amount"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Min Transfer Amount -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="minTransferAmount"
							>
								<label class="mb-1">Min Transfer Amount</label>
								<validation-provider
									#default="{ errors }"
									name="minTransferAmount"
									rules="numeric"
								>
									<b-form-input
										id="minTransferAmount"
										v-model="bonusData.min_transfer"
										placeholder="Enter Min Transfer Amount"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="is_verify"
							>
								<label class="mb-1">Có cần xác thực phone và email</label>
								<v-select
									v-model="bonusData.is_verify"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="yesNoOptions"
									:reduce="val => val.value"
									label="text"
									input-id="is_verify"
									placeholder="Select"
								/>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="is_new"
							>
								<label class="mb-1">Có new hay không?</label>
								<v-select
									v-model="bonusData.is_new"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="yesNoOptions"
									:reduce="val => val.value"
									label="text"
									input-id="is_new"
									placeholder="Select"
								/>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="disposable"
							>
								<label class="mb-1">Dùng 1 lần hay nhiều lần?</label>
								<validation-provider
									#default="{ errors }"
									name="disposable"
								>
									<v-select
										v-model="bonusData.disposable"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="disposableOptions"
										:reduce="val => val.value"
										placeholder="Select "
										label="text"
										input-id="disposable"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="limit_daily"
							>
								<label class="mb-1">Limit daily</label>
								<b-form-input
									id="limit_daily"
									v-model="bonusData.limit_daily"
									placeholder="Enter Limit Daily, Must be at least 1"
								/>
							</b-form-group>
						</b-col>

						<!-- Product -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="product"
							>
								<label class="mb-1">Product</label>
								<v-select
									v-model="bonusData.product_ids"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="productOptions"
									:reduce="val => val.id"
									placeholder="Select Product"
									label="name"
									input-id="product"
									multiple
								/>
							</b-form-group>
						</b-col>

						<!-- Level -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="level"
							>
								<label class="mb-1">Level</label>
								<v-select
									v-model="bonusData.level_ids"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="levelOptions"
									:reduce="val => val.id"
									placeholder="Select Label"
									label="name"
									input-id="level"
									multiple
								/>
							</b-form-group>
						</b-col>

						<!-- Level -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="days"
							>
								<label class="mb-1">Days</label>
								<v-select
									v-model="bonusData.days"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="dayOptions"
									:reduce="val => val.value"
									placeholder="Select Days"
									label="text"
									input-id="days"
									multiple
								/>
							</b-form-group>
						</b-col>

						<!-- Bonus Type -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="type"
							>
								<label class="mb-1"><span class="text-danger">*</span> Bonus Type</label>
								<validation-provider
									#default="{ errors }"
									name="bonusType"
									rules="required"
								>
									<v-select
										v-model="bonusData.bonus_type"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="bonusTypeOptions"
										:reduce="val => val.id"
										label="name"
										:clearable="false"
										placeholder="Select Bonus Type"
										input-id="type"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Start Effective -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label=""
								label-for="startEffective"
							>
								<label class="mb-1">Start Effective</label>
								<flat-pickr
									id="startEffective"
									v-model="bonusData.start_effective"
									class="form-control"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
									placeholder="YYYY-MM-DD hh:mm:ss"
								/>
							</b-form-group>
						</b-col>

						<!-- End Effective -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label=""
								label-for="startEffective"
							>
								<label class="mb-1">End Effective</label>
								<flat-pickr
									id="startEffective"
									v-model="bonusData.end_effective"
									class="form-control"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
									placeholder="YYYY-MM-DD hh:mm:ss"
								/>
							</b-form-group>
						</b-col>

						<!-- Start SignUp -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startSignUp"
							>
								<label class="mb-1">Start Signup</label>
								<flat-pickr
									id="startSignup"
									v-model="bonusData.start_signup"
									class="form-control"
									placeholder="YYYY-MM-DD hh:mm:ss"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<!-- End SignUp -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startSignUp"
							>
								<label class="mb-1">End Signup</label>
								<flat-pickr
									id="startSignup"
									v-model="bonusData.end_signup"
									class="form-control"
									placeholder="YYYY-MM-DD hh:mm:ss"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
						>
							<b-form-group
								label-for="content"
								:key="bonusData.bonus_code_id"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Content') }}</label>
								<validation-provider
									#default="{ errors }"
									name="content"
									rules="required"
								>
									<editor
										:api-key="API_KEY"
										:init="init"
										v-model="bonusData.content"
									/>
								<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="banner"
							>
								<label for=""><span class="text-danger">*</span> {{ $t('Banner') }} <span class="text-secondary">(Không > 10Mb)</span></label>
								<validation-provider
									#default="{ errors }"
									name="banner"
									rules="size:10240"
								>
									<b-form-file
										v-model="bonusData.banner"
										input-id="banner"
										accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
							<b-form-group v-show="bonusData.showBanner">
								<b-img
									:src="bonusData.showBanner"
									fluid
									style="width: 150px;"
									class="mr-2 rounded"
								/>
							</b-form-group>
							<b-button
								v-show="bonusData.banner"
								variant="outline-danger"
								class="btn-sm btn-icon"
								@click="bonusData.banner = null"
							>
								<feather-icon icon="XIcon"/>
							</b-button>
						</b-col>

						<!-- Status -->
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="status"
							>
								<label class="mb-1 d-block">Status</label>
								<b-form-radio
									v-model="bonusData.status"
									name="status"
									value="1"
									inline
								>
									Active
								</b-form-radio>
								<b-form-radio
									v-model="bonusData.status"
									name="status"
									value="2"
									inline
								>
									Inactive
								</b-form-radio>
							</b-form-group>
						</b-col>

					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BRow, BCol, BButton, BForm, BImg, BFormGroup, BFormInput, BFormFile, BModal, VBModal, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import baseUrl from '@/libs/base-url'
import Editor from "@tinymce/tinymce-vue"
import API_KEY from "@/auth/apikey/apikeyeditor";
export default {
	components: {
		BRow,
		BButton,
		BCol,
		BForm,
		BImg,
		BFormGroup,
		BFormInput,
		BFormFile,
		BModal,
		BFormRadio,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		flatPickr,
    	'editor': Editor,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		bonusCodeOptions: {
			type: Array,
			default: null,
		},
		groupNameOptions: {
			type: Array,
			default: null,
		},
		currencyOptions: {
			type: Array,
			default: null,
		},
		productOptions: {
			type: Array,
			default: null,
		},
		levelOptions: {
			type: Array,
			default: null,
		},
		typeOptions: {
			type: Array,
			default: null,
		},
		bonusTypeOptions: {
			type: Array,
			default: null,
		},
		bonusDetail: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
            API_KEY,
			init:{
				height: 600,
				content_style: this.$store.state.appConfig.layout.skin === "dark" ? "body { color: white; background-color:#293046 }" : "",
				plugins: [
					'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
					'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
					'insertdatetime', 'media', 'table', 'wordcount'
				],
				toolbar: ' undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent inden |image',
				skin: this.$store.state.appConfig.layout.skin === "dark" ? 'oxide-dark' : '',
				image_title: true,
				automatic_uploads: true,
				file_picker_types: 'image',
				file_picker_callback: function (cb, value, meta) {
					var input = document.createElement('input');
					input.setAttribute('type', 'file');
					input.setAttribute('accept', 'image/*');

					input.onchange = function () {
						var file = this.files[0]
						var reader = new FileReader()
						reader.onload = function () {
							var id = 'blobid' + (new Date()).getTime()
							var blobCache =  tinymce.activeEditor.editorUpload.blobCache
							var base64 = reader.result.split(',')[1]
							var blobInfo = blobCache.create(id, file, base64)
							blobCache.add(blobInfo)
							cb(blobInfo.blobUri(), { title: file.name })
						}
						reader.readAsDataURL(file)
					}
					input.click()
				},
			},
			bonusData: {
				bonus_code_id: '',
				name: '',
				bonus_group_id: '',
				code: '',
				value: '',
				rollover: '',
				currency: 'VND',
				max_bonus_amount: '',
				min_transfer: '',
				product_id: '',
				start_effective: '',
				end_effective: '',
				start_signup: '',
				end_signup: '',
				type: '',
				bonus_type: '',
				status: '',
				content: '',
				banner: null,
				showBanner: null,
			}
		}
	},
	watch: {
		bonusDetail: {
			handler(newVal) {
				if (newVal) {
					this.bonusData = {
						bonus_code_id: newVal.id,
						name: newVal.name,
						bonus_group_id: newVal.group_id,
						code: newVal.code || '',
						value: newVal.value ? newVal.value / 1000 : '',
						rollover: newVal.rollover || '',
						currency: newVal.currency,
						max_bonus_amount: newVal.max_bonus_amount ? newVal.max_bonus_amount / 1000 : '',
						min_transfer: newVal.min_transfer_amount ? newVal.min_transfer_amount / 1000 : '',
						product_id: newVal.product_id || '',
						// start_effective: '',
						// end_effective: '',
						// start_signup: '',
						// end_signup: '',
						type: newVal.value_type,
						status: newVal.status,
						start_effective: newVal.start_effective,
						end_effective: newVal.end_effective,
						start_signup: newVal.start_signup,
						end_signup: newVal.end_signup,
						content: newVal.content,
						banner: null,
						showBanner: newVal.banner ? baseUrl + newVal.banner : null,
						bonus_type: newVal.bonus_type_id,
						limit_daily: newVal.limit_daily,
						is_verify: newVal.is_verify ? newVal.is_verify : '',
						is_new: newVal.is_new ? newVal.is_new : '',
						disposable: newVal.disposable ? newVal.disposable : '',
						product_ids: newVal.product_ids && newVal.product_ids != 'null' ? newVal.product_ids.split(',').map(Number) : '',
						level_ids: newVal.level_ids && newVal.level_ids != 'null' ? newVal.level_ids.split(',').map(Number) : '',
						days: newVal.days && newVal.days != 'null' ? newVal.days.split(',').map(Number) : '',
					}
				}
			},
			deep: true,
		},
		bonusData: {
			handler(newVal) {
				if (!newVal.start_effective) {
					this.bonusData.start_effective = ''
					this.bonusData.end_effective = ''
				} else {
					this.bonusData.start_effective = newVal.start_effective
					this.bonusData.end_effective = newVal.end_effective
				}

				if (!newVal.start_signup) {
					this.bonusData.start_signup = ''
					this.bonusData.end_signup = ''
				} else {
					this.bonusData.start_signup = newVal.start_signup
					this.bonusData.end_signup = newVal.end_signup
				}

				if (newVal.product_id == null) {
					this.bonusData.product_id = ''
				}

				if (newVal.currency == null) {
					this.bonusData.currency = ''
				}
			},
			deep: true,
		},
	},
	methods: {
		validationFormBonus() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					if (!this.bonusData.banner) {
						this.bonusData.banner = ''
					}
					store.dispatch('bonus/editBonusCode', this.bonusData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										this.$bvModal.hide('modal-edit-bonus')
										// this.resetModal()
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
				}
			})
		},
		confirmEditBonus(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormBonus()
		},
		resetForm() {
			this.$emit('update:bonusDetail', null)
			this.bonusData = {
				bonus_code_id: '',
				name: '',
				bonus_group_id: '',
				code: '',
				value: '',
				rollover: '',
				currency: 'VND',
				max_bonus_amount: '',
				min_transfer: '',
				product_id: '',
				start_effective: '',
				end_effective: '',
				start_signup: '',
				end_signup: '',
				type: '',
				bonus_type: '',
				status: '',
				content: '',
				limit_daily: '',
				banner: null,
				showBanner: null,
			}
		}
	},

	setup() {
		const yesNoOptions = [
			{ text: "Có", value: 1 },
			{ text: "Không", value: 2 },
		]
		const disposableOptions = [
			{ text: "1 lần", value: 1 },
			{ text: "Nhiều lần", value: 2 },
		]
		const statusOptions = [
			{ text: "Active", value: 1 },
			{ text: "Inactive", value: 2 },
		]
		const dayOptions = [
			{ text: "Thứ 2", value: 2 },
			{ text: "Thứ 3", value: 3 },
			{ text: "Thứ 4", value: 4 },
			{ text: "Thứ 5", value: 5 },
			{ text: "Thứ 6", value: 6 },
			{ text: "Thứ 7", value: 7 },
			{ text: "Chủ nhật", value: 8 },
		]

		return {
			yesNoOptions,
			disposableOptions,
			statusOptions,
			dayOptions
		}
	}
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';


</style>
